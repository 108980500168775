import * as React from "react";
import Helmet from "react-helmet";
import {StaticQuery, graphql} from "gatsby";
import TeamGrid from "../../components/TeamGrid";
import UpNext from "../../components/UpNext";
import SEO from "../../components/SEO";
const TeamIndexPage = (props) => {
    const upNext = props.data.allMarkdownRemark.nodes[0].frontmatter.upnextlinks.upnextteam;
    const seo = {
      frontmatter: {
        seo: {
          title: "Team",
          description: "Get to know the members of the HOF team",
        },
      },
      fields: {
        slug: "/team",
      },
    };
    return (
      <React.Fragment>
        <SEO {...seo} />
        <Helmet bodyAttributes={{class: "page-team"}}/>
        <section className="section">
          <div className="team-container">
            <div className="page-title">
              <h6 className="has-text-centered">Our Team</h6>
              <h1 className="title has-text-centered">Who we are</h1>
            </div>
            <div className="content">
              <TeamGrid />
            </div>
            <UpNext url={upNext.linkurl} text={upNext.linktext} />
          </div>
        </section>
      </React.Fragment>
    );
}

export default function TeamIndexQuery() {
  return (
    <StaticQuery
      query={graphql`
        query TeamIndexQuery {
           allMarkdownRemark(filter: {frontmatter: {filterKey: {eq: "globalsettings"}}}) {
    nodes {
      frontmatter {
        upnextlinks {
          upnextteam {
            linktext
            linkurl
          }
        }
      }
    }
  }
        }
      `}
      render={(data) => <TeamIndexPage data={data} />}
    />
  );
}
