import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import useSiteMetadata from "./SiteMetadata";

/**
 * @param {PropTypes.InferProps<typeof SEO.propTypes>} props
 */
function SEO(props) {
  const {
    title: siteTitle,
    description: siteDescription,
    image: siteImageUrl,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: props.frontmatter?.seo?.title || siteTitle,
    description: props.frontmatter?.seo?.description || siteDescription,
    image: `${siteUrl}${
      props.frontmatter?.seo?.image?.publicURL || siteImageUrl
    }`,
    url: `${siteUrl}${props.fields?.slug || ""}`,
    twitterUsername,
  };

  const pageTitle =
    props.pageTitle ||
    (seo.title === siteTitle ? seo.title : `${seo.title} | ${siteTitle}`);

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
    </Helmet>
  );
}

SEO.propTypes = {
  pageTitle: PropTypes.string,
  frontmatter: PropTypes.shape({
    seo: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
    }),
  }),
  fields: PropTypes.shape({
    slug: PropTypes.string,
  }),
};

export default SEO;

export const query = graphql`
  fragment SEOFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      seo {
        title
        description
        image {
          publicURL
        }
      }
    }
  }
`;
