import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const TeamGrid = (props) => {
    const { data } = props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-mobile is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column team-list-tile is-half-mobile is-one-third-tablet" key={post.id}>
               <Link
                      className="title has-text-primary"
                      to={post.fields.slug}
                    >
              <article
              className = {
                  `team-list-item`
                } >
                {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `Image of ${post.frontmatter.title}`,
                            style: {width: "100%"},
                            quality: 100
                          }}
                          alt={`Image of ${post.frontmatter.title}`}
                        />
                      </div>
                    ) : null }
                    <div className='article-content'>
                          <h4>{post.frontmatter.title}</h4>
                          <p>{post.frontmatter.teamtitle}</p>
                      </div>
              </article>
               </Link>
            </div>
          ))}
      </div>
    )
}

TeamGrid.propTypes = {
  title: PropTypes.string,
  teamtitle: PropTypes.string,
  slug: PropTypes.string,
  featuredimage: PropTypes.object
}


export default function QueryTeam() {
  return (
    <StaticQuery
      query={graphql`
        query TeamQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "team-member" } } }
            sort: { order: ASC, fields: frontmatter___position }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  teamtitle
                  featuredpost
                  position
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 740
                        height: 740
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <TeamGrid data={data} count={count} />}
    />
  );
}
