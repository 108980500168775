import React from "react";
import {Link} from 'gatsby';
import PropTypes from "prop-types";



//Inherits information manually populated on the pages that call this component. url, text, and image must be manually entered in order to display them.
const UpNext = (props) => {
    //console.log(props);
    const { url, text } = props;

    return (
        <React.Fragment>
            <section className="up-next">
                <Link to={url}>
                    <div className="inner is-flex is-align-items-center is-justify-content-center">
                       <h3>{text}</h3>
                    </div>
                </Link>
            </section>
        </React.Fragment>
    )
};

UpNext.propTypes = {
    url: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object
};

export default UpNext;
